import React from 'react'

const IconWithText = ({
    icon,
    text,
    iconSize = '1.4rem',
    padding = '0.4rem',
    ...props
    }) => {

    const style = {
        display: 'flex',
        alignItems: 'center',
        padding: padding,
    }

    return (
        <div className='icon-with-text' style={style}>
            <span className='icon' style={{ marginRight: '1rem' }}>
                {icon}
            </span>
            <span className='text'>
                {text}
            </span>
        </div>
    )
}

export default IconWithText