import React, { lazy, Suspense, useRef, useEffect, useState } from 'react'
import { window } from "browser-monads"

import { pageStore } from "state/store-zustand";

import Details from "./details"
import TimeLocation from "./timeLocation"
const ShareButtons = lazy(() => import("components/post/shareButtons"))

const EventLayout = ({
    placeID = null,
    slug = null,
    eventDetails = null,
    hasError = false,
    ...props
}) => {

    const placeLayout = pageStore((state) => state.placeLayout)

    const detailsOnly = placeLayout == 'details' ? true : false
    const mapOnly = placeLayout == 'map' ? true : false

    return (
        <div className={`event post-body ${eventDetails?.id}`}>
            <div className="columns is-flex-tablet">
                {hasError &&
                    <div className={`column left is-7-desktop is-12-tablet`}>
                        <div>This event already happened or has been removed. </div>
                    </div>
                }
                {eventDetails &&
                    <>
                        {mapOnly == false
                            ? (
                                <div className={`column left is-7-desktop is-12-tablet`}>
                                    <Details eventDetails={eventDetails} />
                                </div>
                            )
                            : null
                        }

                        {detailsOnly == false
                            ? (
                                <div className={`column right is-5-desktop is-12-tablet`}>
                                    <TimeLocation eventDetails={eventDetails} />
                                </div>
                            )
                            : null
                        }
                    </>
                }
            </div>

            <section>
                <Suspense fallback={<span>...</span>}>
                    <ShareButtons
                        className="desktop-share-buttons"
                        url={window.location.href}
                    />
                </Suspense>
            </section>
        </div>
    )

}

export default EventLayout