import React from 'react'

import Skeleton from "react-loading-skeleton"

const LoadingProfile = () => {
  return (
    <div className='profileSkeleton' style={{
      margin: '0 auto',
      maxWidth: '100rem',
      padding: '4rem 2rem'
    }}>
      <Skeleton height={'4rem'} width={'30rem'} style={{ margin: '2rem 0' }} />
      <Skeleton width={'30rem'} />
      <Skeleton height={'38rem'} width={'60rem'} style={{ maxWidth: '100%' }} />
      <Skeleton count={3} width={'30rem'} />
      <Skeleton count={6} width={'20rem'} />
    </div>
  )
}

export default LoadingProfile