// Details inside of template/event
import React, { useState } from 'react'

import Chip from 'components/shared/chip/chip'

// TODO: Make shared component
const Vibes = ({
  vibes = [],
  numVibesShown = 3,
  ...props
}) => {

  const [vibesExpanded, setVibesExpanded] = useState(false)

  const toggleMoreVibes = function () {
    setVibesExpanded(!vibesExpanded)
  }

  const displayVibes = vibesExpanded ? vibes : vibes?.slice(0, numVibesShown)
  const displayVibesUnq = [...new Set(displayVibes)]


  const vibeButtons = displayVibesUnq?.map(vibe => {
    const pageLink = `/vibes/${vibe?.slug ? vibe.slug : vibe}`

    return (
      <a href={pageLink} key={vibe}>
        <Chip key={vibe} text={vibe} />
      </a>
    )
  })

  const showMore = vibes?.length > numVibesShown
    ? (
      <a className="showMore" onClick={toggleMoreVibes}>
        {vibesExpanded ? (
          <Chip key={"Show less"} text={"Show less"} />
        ) : (
          <Chip key={"See All"} text={"See All"} />
        )}
      </a>
    )
    : null



  return (
    <div className="vibes">
      {vibeButtons}
      {showMore}
    </div>
  )
}

export default Vibes