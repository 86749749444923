// Details inside of template/event
import React from 'react'
import { pageStore } from "state/store-zustand";

import EventBadge from 'components/shared/eventBadge'
import Vibes from 'components/elements/vibes'

import { font_size_large } from 'styles/variables.js'

const Details = (props) => {
  const embedded = pageStore((state) => state.embedded)

  const { eventDetails } = props
  const {
    id,
    properties,
    title
  } = eventDetails || {}

  const {
    description,
    hotspots_place,
    location,
    name,
    price,
    start_date,
    end_date,
    vibes = [],
    vibemap_images,
    url
  } = properties || {} // Safely destructure

  const images_featured = vibemap_images.filter(image => {
    return image?.featured === true || image.tags?.includes('featured')
  })

  const image = vibemap_images && vibemap_images.length > 0
    ? vibemap_images[0]
    : hotspots_place?.properties?.vibemap_images
      ? hotspots_place?.properties?.vibemap_images[0]
      : null

  const placeVibes = hotspots_place?.properties?.vibes
    ? hotspots_place?.properties?.vibes
    : []

  const vibesCombined = vibes.concat(placeVibes)

  const refreshByDay = (date) => {
    const d = new Date(date)
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`
  }

  return (
    <>

      <h1>{name ? name : title}</h1>
      <section>
        <EventBadge dateTime={start_date} />
        <img
          alt={image?.alt_text}
          caption={image?.caption}
          className='eventImage'
          loading='lazy'
          src={image?.original + '?refresh=' + refreshByDay(start_date)}
          title={title}
        />
      </section>
      <Vibes vibes={vibesCombined} numVibesShown={6} />
      <div className="description" style={{ whiteSpace: 'pre-wrap', fontSize: font_size_large }}>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div >

    </>
  )
}

export default Details
