import React, { lazy, Suspense, useEffect, useState } from "react"
import { useLocation } from '@reach/router'
import { trackEvent } from 'components/analytics'
import { dataStore, mapStore, pageStore, locationStore } from "state/store-zustand";
import usePageLoading from 'components/utils/usePageLoading'
import useContextTheme from 'components/utils/useContextTheme'
import useIFrameResizer from "components/utils/useIFrameResizer"

import { eventToSchema, linksToBreadcrumbs } from 'components/utils/schemaUtils'
import { cities, fetchPlacesDetails } from "vibemap-constants/dist/helpers"

import SEO from "components/seo"
// Lazy load, since it's not used on the server and not always needed
const PostHeader = lazy(() => import("components/header/postHeader"))

import Breadcrumbs from 'components/elements/breadcrumbs'
import LoadingProfile from "components/elements/loading/loadingProfile"

import EventLayout from "./eventLayout"
import Footer from "components/footer"

import EventsBlock from "components/post/blocks/eventsBlock"

import "components/post/post.scss"
import "styles/pages.scss"
import "styles/details.scss"
import "./event.scss"

const EventDetails = ({ data, pageContext, pageResources, params, ...props }) => {

  // Page context and options
  const { event, place } = pageContext
  const pageLoader = usePageLoading()
  const themeContext = useContextTheme()

  // Support for iframe resizing
  const [hasResizer, parentIFrame] = useIFrameResizer()
  if (hasResizer) {
    window.parentIframe = parentIFrame
  }

  const compact = pageStore((state) => state.compact)
  const embedded = pageStore((state) => state.embedded)
  const setCityCurrent = locationStore((state) => state.setCityCurrent)
  const setLocationCurrent = locationStore((state) => state.setLocationCurrent)
  const setHasLocation = locationStore((state) => state.setHasLocation)
  const setPlaceCurrent = dataStore((state) => state.setPlaceCurrent)
  const setZoomCurrent = locationStore((state) => state.setZoomCurrent)
  const themeClass = pageStore((state) => state.themeClass)
  const themeObject = pageStore((state) => state.themeObject)


  // Can be preloaded from prop or router state
  const [eventDetails, setEventDetails] = useState(event
    ? event
    : null)

  const [links, setLinks] = useState([])
  const [breadcrumbItems, setBreadcrumbItems] = useState()
  const urlLocation = useLocation()

  const alreadyLoaded = eventDetails != null
  const [isLoading, setIsLoading] = useState(!alreadyLoaded)
  const [hasError, setHasError] = useState(false) // TODO: Set default from SSR data

  const { id } = eventDetails || {}
  const { properties = {} } = eventDetails || {}

  // Set ID from param or pre-fetched data
  const eventID = id
    ? id
    : params.id
      ? params.id
      : null

  const {
    address,
    city,
    description,
    hotspots_place,
    location,
    price,
    vibemap_images,
    vibes = [],
    url
  } = properties || {} // Safely destructure

  const vibesCombined = vibes.concat(hotspots_place?.properties?.vibes)
  const showRelated = isLoading == false && eventDetails

  trackEvent('viewed_event', 'Events', url)

  //console.log('TODO: Fetch events for these vibes ', properties, location, vibes);

  const cityName = hotspots_place ? hotspots_place?.properties?.city : city
  const cityObject = cityName
    ? cities.find(city => city.name === cityName)
    : null

  // Add breadcrumbs
  const cityDep = JSON.stringify(cityObject)

  useEffect(() => {
    const links = []

    if (cityObject) {
      links.push({
        name: cityObject.name,
        slug: cityObject.slug,
        type: 'city'
      })
      setCityCurrent(cityObject)
      setHasLocation(true)
    }

    links.push({
      name: `Events`,
      slug: `events`,
      type: 'index'
    })

    const breadcrumbItems = linksToBreadcrumbs(links)

    setLinks(links)
    setBreadcrumbItems(breadcrumbItems)

  }, [cityDep])


  const latitude = hotspots_place?.geometry?.coordinates[1]
  const longitude = hotspots_place?.geometry?.coordinates[0]

  useEffect(() => {
    if (latitude && longitude) {
      setLocationCurrent({
        latitude: latitude,
        longitude: longitude
      })
      setHasLocation(true)
    }
  }, [latitude, longitude])

  useEffect(() => {
    const fetchData = async (id) => {
      setIsLoading(true)
      const details = await fetchPlacesDetails(id, 'event')
        .catch(e => {
          console.log(`Error with event `, id, e)
          setHasError(true)
        })

      if (details) {
        setEventDetails(details.data)
        setIsLoading(false)

        const place = details.data?.properties?.hotspots_place
        if (place) {
          setPlaceCurrent(place)
          setZoomCurrent(15)
        }
      } else {
        setHasError(true)
        setIsLoading(false)
      }
    }

    if (eventDetails == null) {
      fetchData(eventID)
    }

  }, [setEventDetails])

  const handleCalendar = () => {
    // Handle calendar add
  }

  // TODO: fix until the block editor works
  //postData.postDetails.vibeset = 'oldschool'
  const breadcrumbs = <Breadcrumbs links={links} />
  const schemaData = []
  const hasEventData = eventDetails?.name != "" && eventDetails?.name != null
  const eventSchema = eventDetails && hasEventData && eventToSchema(eventDetails)

  if (breadcrumbItems) {
    schemaData.push(breadcrumbItems)
  }

  if (eventSchema) {
    schemaData.push(eventSchema)
  }

  const title = `${eventDetails
    ? eventDetails?.title || eventDetails?.properties?.name
    : ''} | ${city ? city.name : ''} Events | Vibemap`

  const slug = eventDetails?.properties?.slug

  const containerClass = hasResizer
    ? `container-resizable`
    : `container`

  const classes = [
    'page',
    'event-details',
    pageLoader,
    embedded && 'embedded',
    compact && 'compact',
    themeClass,
    containerClass
  ].filter(Boolean).join(' ');

  const mainClass = `${classes}`

  const styleRelated = {
    paddingBottom: '2rem',
    margin: '0 auto',
    maxWidth: '113rem'
  }

  return (
    <div>
      <SEO
        lang="en-US"
        schemaData={schemaData}
        title={title}
      />
      <main className={mainClass}>
        {embedded ?
          <Suspense fallback={null}>
            <PostHeader
              handleCalendar={handleCalendar}
              eventDetails={eventDetails}
              showCalendar={true}
              showSave={false}
              breadcrumbs={breadcrumbs}
              url={`https://vibemap.com/events/details/${eventID}`} />
          </Suspense>
        : null}

        {/* TODO: Is loading */}
        {isLoading &&
          <LoadingProfile />
        }

        {!isLoading && eventDetails &&
          <EventLayout
            slug={slug} // Side effect triggers render
            eventDetails={eventDetails}
            hasError={hasError} />
        }
        <div>
          <section style={styleRelated}>
            {showRelated &&
              <EventsBlock
                //activity={activity}
                city={cityObject?.slug}
                distance={2.5}
                latitude={latitude}
                longitude={longitude}
                header={'More Events'}
                style='carousel'
                vibes={vibesCombined}
              />
            }
          </section>
        </div>
      </main>
      <Footer />
    </div>
  )
}
export default EventDetails